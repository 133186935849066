import { company } from '@/lib/features'
import { CurrencySymbol } from '@/modules/productPackages/enums/CurrencySymbol'

export const CountryCodes = Object.freeze({
  GERMANY: 'DE',
  SWITZERLAND: 'CH',
  AUSTRIA: 'AT',
  UNITED_KINGDOM: 'GB',
  ALBANIA: 'AL',
  ANDORRA: 'AD',
  BELARUS: 'BY',
  BELGIUM: 'BE',
  BOSNIA_AND_HERZEGOVINA: 'BA',
  BULGARIA: 'BG',
  CROATIA: 'HR',
  CYPRUS: 'CY',
  CZECH_REPUBLIC: 'CZ',
  DENMARK: 'DK',
  ESTONIA: 'EE',
  FINLAND: 'FI',
  FRANCE: 'FR',
  GREECE: 'GR',
  HUNGARY: 'HU',
  ICELAND: 'IS',
  IRELAND: 'IE',
  ITALY: 'IT',
  LATVIA: 'LV',
  LIECHTENSTEIN: 'LI',
  LITHUANIA: 'LT',
  LUXEMBOURG: 'LU',
  MALTA: 'MT',
  MOLDOVA: 'MD',
  MONACO: 'MC',
  MONTENEGRO: 'ME',
  NETHERLANDS: 'NL',
  NORTH_MACEDONIA: 'MK',
  NORWAY: 'NO',
  POLAND: 'PL',
  PORTUGAL: 'PT',
  ROMANIA: 'RO',
  RUSSIA: 'RU',
  SAN_MARINO: 'SM',
  SERBIA: 'RS',
  SLOVAKIA: 'SK',
  SLOVENIA: 'SI',
  SPAIN: 'ES',
  SWEDEN: 'SE',
  UKRAINE: 'UA',
  VATICAN_CITY: 'VA',
  ANTIGUA_AND_BARBUDA: 'AG',
  ARGENTINA: 'AR',
  BAHAMAS: 'BS',
  BARBADOS: 'BB',
  BELIZE: 'BZ',
  BOLIVIA: 'BO',
  BRAZIL: 'BR',
  CANADA: 'CA',
  CHILE: 'CL',
  COLOMBIA: 'CO',
  COSTA_RICA: 'CR',
  CUBA: 'CU',
  DOMINICA: 'DM',
  DOMINICAN_REPUBLIC: 'DO',
  ECUADOR: 'EC',
  EL_SALVADOR: 'SV',
  GRENADA: 'GD',
  GUATEMALA: 'GT',
  GUYANA: 'GY',
  HAITI: 'HT',
  HONDURAS: 'HN',
  JAMAICA: 'JM',
  MEXICO: 'MX',
  NICARAGUA: 'NI',
  PANAMA: 'PA',
  PARAGUAY: 'PY',
  PERU: 'PE',
  SAINT_KITTS_AND_NEVIS: 'KN',
  SAINT_LUCIA: 'LC',
  SAINT_VINCENT_AND_THE_GRENADINES: 'VC',
  SURINAME: 'SR',
  TRINIDAD_AND_TOBAGO: 'TT',
  UNITED_STATES: 'US',
  URUGUAY: 'UY',
  VENEZUELA: 'VE',
  AFGHANISTAN: 'AF',
  ARMENIA: 'AM',
  AZERBAIJAN: 'AZ',
  BAHRAIN: 'BH',
  BANGLADESH: 'BD',
  BHUTAN: 'BT',
  BRUNEI: 'BN',
  CAMBODIA: 'KH',
  CHINA: 'CN',
  GEORGIA: 'GE',
  INDIA: 'IN',
  INDONESIA: 'ID',
  IRAN: 'IR',
  IRAQ: 'IQ',
  ISRAEL: 'IL',
  JAPAN: 'JP',
  JORDAN: 'JO',
  KAZAKHSTAN: 'KZ',
  KUWAIT: 'KW',
  KYRGYZSTAN: 'KG',
  LAOS: 'LA',
  LEBANON: 'LB',
  MALAYSIA: 'MY',
  MALDIVES: 'MV',
  MONGOLIA: 'MN',
  MYANMAR_BURMA: 'MM',
  NEPAL: 'NP',
  NORTH_KOREA: 'KP',
  OMAN: 'OM',
  PAKISTAN: 'PK',
  PALESTINE: 'PS',
  PHILIPPINES: 'PH',
  QATAR: 'QA',
  SAUDI_ARABIA: 'SA',
  SINGAPORE: 'SG',
  SOUTH_KOREA: 'KR',
  SRI_LANKA: 'LK',
  SYRIA: 'SY',
  TAIWAN: 'TW',
  TAJIKISTAN: 'TJ',
  THAILAND: 'TH',
  TIMOR_LESTE: 'TL',
  TURKEY: 'TR',
  TURKMENISTAN: 'TM',
  UNITED_ARAB_EMIRATES: 'AE',
  UZBEKISTAN: 'UZ',
  VIETNAM: 'VN',
  YEMEN: 'YE',
  AUSTRALIA: 'AU',
  FIJI: 'FJ',
  KIRIBATI: 'KI',
  MARSHALL_ISLANDS: 'MH',
  MICRONESIA: 'FM',
  NAURU: 'NR',
  NEW_ZEALAND: 'NZ',
  PALAU: 'PW',
  PAPUA_NEW_GUINEA: 'PG',
  SAMOA: 'WS',
  SOLOMON_ISLANDS: 'SB',
  TONGA: 'TO',
  TUVALU: 'TV',
  VANUATU: 'VU',
  ALGERIA: 'DZ',
  ANGOLA: 'AO',
  BENIN: 'BJ',
  BOTSWANA: 'BW',
  BURKINA_FASO: 'BF',
  BURUNDI: 'BI',
  CABO_VERDE: 'CV',
  CAMEROON: 'CM',
  CENTRAL_AFRICAN_REPUBLIC: 'CF',
  CHAD: 'TD',
  COMOROS: 'KM',
  DJIBOUTI: 'DJ',
  EGYPT: 'EG',
  EQUATORIAL_GUINEA: 'GQ',
  ERITREA: 'ER',
  ESWATINI: 'SZ',
  ETHIOPIA: 'ET',
  GABON: 'GA',
  GAMBIA: 'GM',
  GHANA: 'GH',
  GUINEA: 'GN',
  GUINEA_BISSAU: 'GW',
  IVORY_COAST: 'CI',
  KENYA: 'KE',
  LESOTHO: 'LS',
  LIBERIA: 'LR',
  LIBYA: 'LY',
  MADAGASCAR: 'MG',
  MALAWI: 'MW',
  MALI: 'ML',
  MAURITANIA: 'MR',
  MAURITIUS: 'MU',
  MOROCCO: 'MA',
  MOZAMBIQUE: 'MZ',
  NAMIBIA: 'NA',
  NIGER: 'NE',
  NIGERIA: 'NG',
  RWANDA: 'RW',
  SAO_TOME_AND_PRINCIPE: 'ST',
  SENEGAL: 'SN',
  SEYCHELLES: 'SC',
  SIERRA_LEONE: 'SL',
  SOMALIA: 'SO',
  SOUTH_AFRICA: 'ZA'
})

export const Countries = [
  { value: 'Germany', text: 'product-finder.countries.germany', currencySymbol: CurrencySymbol.EURO, countryCode: CountryCodes.GERMANY },
  { value: 'Switzerland', text: 'product-finder.countries.switzerland', currencySymbol: CurrencySymbol.SWISSFRANC, countryCode: CountryCodes.SWITZERLAND },
  { value: 'Austria', text: 'product-finder.countries.austria', currencySymbol: CurrencySymbol.EURO, countryCode: CountryCodes.AUSTRIA },
  { value: 'United Kingdom', text: 'product-finder.countries.united-kingdom', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.UNITED_KINGDOM },
  { value: 'Albania', text: 'product-finder.countries.albania', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.ALBANIA },
  { value: 'Andorra', text: 'product-finder.countries.andorra', currencySymbol: CurrencySymbol.EURO, countryCode: CountryCodes.ANDORRA },
  { value: 'Belarus', text: 'product-finder.countries.belarus', currencySymbol: CurrencySymbol.EURO, countryCode: CountryCodes.BELARUS },
  { value: 'Belgium', text: 'product-finder.countries.belgium', currencySymbol: CurrencySymbol.EURO, countryCode: CountryCodes.BELGIUM },
  {
    value: 'Bosnia and Herzegovina',
    text: 'product-finder.countries.bosnia-and-herzegovina',
    currencySymbol: CurrencySymbol.USDOLLAR,
    countryCode: CountryCodes.BOSNIA_AND_HERZEGOVINA
  },
  { value: 'Bulgaria', text: 'product-finder.countries.bulgaria', currencySymbol: CurrencySymbol.EURO, countryCode: CountryCodes.BULGARIA },
  { value: 'Croatia', text: 'product-finder.countries.croatia', currencySymbol: CurrencySymbol.EURO, countryCode: CountryCodes.CROATIA },
  { value: 'Cyprus', text: 'product-finder.countries.cyprus', currencySymbol: CurrencySymbol.EURO, countryCode: CountryCodes.CYPRUS },
  { value: 'Czech Republic', text: 'product-finder.countries.czech-republic', currencySymbol: CurrencySymbol.EURO, countryCode: CountryCodes.CZECH_REPUBLIC },
  { value: 'Denmark', text: 'product-finder.countries.denmark', currencySymbol: CurrencySymbol.EURO, countryCode: CountryCodes.DENMARK },
  { value: 'Estonia', text: 'product-finder.countries.estonia', currencySymbol: CurrencySymbol.EURO, countryCode: CountryCodes.ESTONIA },
  { value: 'Finland', text: 'product-finder.countries.finland', currencySymbol: CurrencySymbol.EURO, countryCode: CountryCodes.FINLAND },
  { value: 'France', text: 'product-finder.countries.france', currencySymbol: CurrencySymbol.EURO, countryCode: CountryCodes.FRANCE },
  { value: 'Greece', text: 'product-finder.countries.greece', currencySymbol: CurrencySymbol.EURO, countryCode: CountryCodes.GREECE },
  { value: 'Hungary', text: 'product-finder.countries.hungary', currencySymbol: CurrencySymbol.EURO, countryCode: CountryCodes.HUNGARY },
  { value: 'Iceland', text: 'product-finder.countries.iceland', currencySymbol: CurrencySymbol.EURO, countryCode: CountryCodes.ICELAND },
  { value: 'Ireland', text: 'product-finder.countries.ireland', currencySymbol: CurrencySymbol.EURO, countryCode: CountryCodes.IRELAND },
  { value: 'Italy', text: 'product-finder.countries.italy', currencySymbol: CurrencySymbol.EURO, countryCode: CountryCodes.ITALY },
  { value: 'Latvia', text: 'product-finder.countries.latvia', currencySymbol: CurrencySymbol.EURO, countryCode: CountryCodes.LATVIA },
  { value: 'Liechtenstein', text: 'product-finder.countries.liechtenstein', currencySymbol: CurrencySymbol.EURO, countryCode: CountryCodes.LIECHTENSTEIN },
  { value: 'Lithuania', text: 'product-finder.countries.lithuania', currencySymbol: CurrencySymbol.EURO, countryCode: CountryCodes.LITHUANIA },
  { value: 'Luxembourg', text: 'product-finder.countries.luxembourg', currencySymbol: CurrencySymbol.EURO, countryCode: CountryCodes.LUXEMBOURG },
  { value: 'Malta', text: 'product-finder.countries.malta', currencySymbol: CurrencySymbol.EURO, countryCode: CountryCodes.MALTA },
  { value: 'Moldova', text: 'product-finder.countries.moldova', currencySymbol: CurrencySymbol.EURO, countryCode: CountryCodes.MOLDOVA },
  { value: 'Monaco', text: 'product-finder.countries.monaco', currencySymbol: CurrencySymbol.EURO, countryCode: CountryCodes.MONACO },
  { value: 'Montenegro', text: 'product-finder.countries.montenegro', currencySymbol: CurrencySymbol.EURO, countryCode: CountryCodes.MONTENEGRO },
  { value: 'Netherlands', text: 'product-finder.countries.netherlands', currencySymbol: CurrencySymbol.EURO, countryCode: CountryCodes.NETHERLANDS },
  { value: 'North Macedonia', text: 'product-finder.countries.north-macedonia', currencySymbol: CurrencySymbol.EURO, countryCode: CountryCodes.NORTH_MACEDONIA },
  { value: 'Norway', text: 'product-finder.countries.norway', currencySymbol: CurrencySymbol.EURO, countryCode: CountryCodes.NORWAY },
  { value: 'Poland', text: 'product-finder.countries.poland', currencySymbol: CurrencySymbol.EURO, countryCode: CountryCodes.POLAND },
  { value: 'Portugal', text: 'product-finder.countries.portugal', currencySymbol: CurrencySymbol.EURO, countryCode: CountryCodes.PORTUGAL },
  { value: 'Romania', text: 'product-finder.countries.romania', currencySymbol: CurrencySymbol.EURO, countryCode: CountryCodes.ROMANIA },
  { value: 'Russia', text: 'product-finder.countries.russia', currencySymbol: CurrencySymbol.EURO, countryCode: CountryCodes.RUSSIA },
  { value: 'San Marino', text: 'product-finder.countries.san-marino', currencySymbol: CurrencySymbol.EURO, countryCode: CountryCodes.SAN_MARINO },
  { value: 'Serbia', text: 'product-finder.countries.serbia', currencySymbol: CurrencySymbol.EURO, countryCode: CountryCodes.SERBIA },
  { value: 'Slovakia', text: 'product-finder.countries.slovakia', currencySymbol: CurrencySymbol.EURO, countryCode: CountryCodes.SLOVAKIA },
  { value: 'Slovenia', text: 'product-finder.countries.slovenia', currencySymbol: CurrencySymbol.EURO, countryCode: CountryCodes.SLOVENIA },
  { value: 'Spain', text: 'product-finder.countries.spain', currencySymbol: CurrencySymbol.EURO, countryCode: CountryCodes.SPAIN },
  { value: 'Sweden', text: 'product-finder.countries.sweden', currencySymbol: CurrencySymbol.EURO, countryCode: CountryCodes.SWEDEN },
  { value: 'Ukraine', text: 'product-finder.countries.ukraine', currencySymbol: CurrencySymbol.EURO, countryCode: CountryCodes.UKRAINE },
  { value: 'Vatican City', text: 'product-finder.countries.vatican-city', currencySymbol: CurrencySymbol.EURO, countryCode: CountryCodes.VATICAN_CITY },
  { value: 'Antigua and Barbuda', text: 'product-finder.countries.antigua-and-barbuda', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.ANTIGUA_AND_BARBUDA },
  { value: 'Argentina', text: 'product-finder.countries.argentina', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.ARGENTINA },
  { value: 'Bahamas', text: 'product-finder.countries.bahamas', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.BAHAMAS },
  { value: 'Barbados', text: 'product-finder.countries.barbados', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.BARBADOS },
  { value: 'Belize', text: 'product-finder.countries.belize', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.BELIZE },
  { value: 'Bolivia', text: 'product-finder.countries.bolivia', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.BOLIVIA },
  { value: 'Brazil', text: 'product-finder.countries.brazil', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.BRAZIL },
  { value: 'Canada', text: 'product-finder.countries.canada', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.CANADA },
  { value: 'Chile', text: 'product-finder.countries.chile', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.CHILE },
  { value: 'Colombia', text: 'product-finder.countries.colombia', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.COLOMBIA },
  { value: 'Costa Rica', text: 'product-finder.countries.costa-rica', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.COSTA_RICA },
  { value: 'Cuba', text: 'product-finder.countries.cuba', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.CUBA },
  { value: 'Dominica', text: 'product-finder.countries.dominica', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.DOMINICA },
  { value: 'Dominican Republic', text: 'product-finder.countries.dominican-republic', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.DOMINICAN_REPUBLIC },
  { value: 'Ecuador', text: 'product-finder.countries.ecuador', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.ECUADOR },
  { value: 'El Salvador', text: 'product-finder.countries.el-salvador', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.EL_SALVADOR },
  { value: 'Grenada', text: 'product-finder.countries.grenada', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.GRENADA },
  { value: 'Guatemala', text: 'product-finder.countries.guatemala', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.GUATEMALA },
  { value: 'Guyana', text: 'product-finder.countries.guyana', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.GUYANA },
  { value: 'Haiti', text: 'product-finder.countries.haiti', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.HAITI },
  { value: 'Honduras', text: 'product-finder.countries.honduras', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.HONDURAS },
  { value: 'Jamaica', text: 'product-finder.countries.jamaica', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.JAMAICA },
  { value: 'Mexico', text: 'product-finder.countries.mexico', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.MEXICO },
  { value: 'Nicaragua', text: 'product-finder.countries.nicaragua', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.NICARAGUA },
  { value: 'Panama', text: 'product-finder.countries.panama', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.PANAMA },
  { value: 'Paraguay', text: 'product-finder.countries.paraguay', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.PARAGUAY },
  { value: 'Peru', text: 'product-finder.countries.peru', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.PERU },
  { value: 'Saint Kitts and Nevis', text: 'product-finder.countries.saint-kitts-and-nevis', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.SAINT_KITTS_AND_NEVIS },
  { value: 'Saint Lucia', text: 'product-finder.countries.saint-lucia', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.SAINT_LUCIA },
  { value: 'Saint Vincent and the Grenadines', text: 'product-finder.countries.saint-vincent-and-the-grenadines', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.SAINT_VINCENT_AND_THE_GRENADINES },
  { value: 'Suriname', text: 'product-finder.countries.suriname', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.SURINAME },
  { value: 'Trinidad and Tobago', text: 'product-finder.countries.trinidad-and-tobago', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.TRINIDAD_AND_TOBAGO },
  { value: 'United States', text: 'product-finder.countries.united-states', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.UNITED_STATES },
  { value: 'Uruguay', text: 'product-finder.countries.uruguay', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.URUGUAY },
  { value: 'Venezuela', text: 'product-finder.countries.venezuela', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.VENEZUELA },
  { value: 'Afghanistan', text: 'product-finder.countries.afghanistan', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.AFGHANISTAN },
  { value: 'Armenia', text: 'product-finder.countries.armenia', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.ARMENIA },
  { value: 'Azerbaijan', text: 'product-finder.countries.azerbaijan', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.AZERBAIJAN },
  { value: 'Bahrain', text: 'product-finder.countries.bahrain', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.BAHRAIN },
  { value: 'Bangladesh', text: 'product-finder.countries.bangladesh', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.BANGLADESH },
  { value: 'Bhutan', text: 'product-finder.countries.bhutan', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.BHUTAN },
  { value: 'Brunei', text: 'product-finder.countries.brunei', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.BRUNEI },
  { value: 'Cambodia', text: 'product-finder.countries.cambodia', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.CAMBODIA },
  { value: 'China', text: 'product-finder.countries.china', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.CHINA },
  { value: 'Cyprus', text: 'product-finder.countries.cyprus', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.CYPRUS },
  { value: 'Georgia', text: 'product-finder.countries.georgia', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.GEORGIA },
  { value: 'India', text: 'product-finder.countries.india', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.INDIA },
  { value: 'Indonesia', text: 'product-finder.countries.indonesia', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.INDONESIA },
  { value: 'Iran', text: 'product-finder.countries.iran', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.IRAN },
  { value: 'Iraq', text: 'product-finder.countries.iraq', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.IRAQ },
  { value: 'Israel', text: 'product-finder.countries.israel', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.ISRAEL },
  { value: 'Japan', text: 'product-finder.countries.japan', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.JAPAN },
  { value: 'Jordan', text: 'product-finder.countries.jordan', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.JORDAN },
  { value: 'Kazakhstan', text: 'product-finder.countries.kazakhstan', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.KAZAKHSTAN },
  { value: 'Kuwait', text: 'product-finder.countries.kuwait', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.KUWAIT },
  { value: 'Laos', text: 'product-finder.countries.laos', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.LAOS },
  { value: 'Lebanon', text: 'product-finder.countries.lebanon', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.LEBANON },
  { value: 'Malaysia', text: 'product-finder.countries.malaysia', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.MALAYSIA },
  { value: 'Maldives', text: 'product-finder.countries.maldives', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.MALDIVES },
  { value: 'Mongolia', text: 'product-finder.countries.mongolia', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.MONGOLIA },
  { value: 'Myanmar (Burma)', text: 'product-finder.countries.myanmar-(burma)', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.MYANMAR_BURMA },
  { value: 'Nepal', text: 'product-finder.countries.nepal', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.NEPAL },
  { value: 'North Korea', text: 'product-finder.countries.north-korea', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.NORTH_KOREA },
  { value: 'Oman', text: 'product-finder.countries.oman', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.OMAN },
  { value: 'Pakistan', text: 'product-finder.countries.pakistan', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.PAKISTAN },
  { value: 'Palestine', text: 'product-finder.countries.palestine', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.PALESTINE },
  { value: 'Philippines', text: 'product-finder.countries.philippines', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.PHILIPPINES },
  { value: 'Qatar', text: 'product-finder.countries.qatar', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.QATAR },
  { value: 'Saudi Arabia', text: 'product-finder.countries.saudi-arabia', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.SAUDI_ARABIA },
  { value: 'Singapore', text: 'product-finder.countries.singapore', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.SINGAPORE },
  { value: 'South Korea', text: 'product-finder.countries.south-korea', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.SOUTH_KOREA },
  { value: 'Sri Lanka', text: 'product-finder.countries.sri-lanka', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.SRI_LANKA },
  { value: 'Syria', text: 'product-finder.countries.syria', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.SYRIA },
  { value: 'Taiwan', text: 'product-finder.countries.taiwan', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.TAIWAN },
  { value: 'Tajikistan', text: 'product-finder.countries.tajikistan', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.TAJIKISTAN },
  { value: 'Thailand', text: 'product-finder.countries.thailand', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.THAILAND },
  { value: 'Timor-Leste', text: 'product-finder.countries.timor-leste', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.TIMOR_LESTE },
  { value: 'Turkey', text: 'product-finder.countries.turkey', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.TURKEY },
  { value: 'Turkmenistan', text: 'product-finder.countries.turkmenistan', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.TURKMENISTAN },
  { value: 'United Arab Emirates', text: 'product-finder.countries.united-arab-emirates', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.UNITED_ARAB_EMIRATES },
  { value: 'Uzbekistan', text: 'product-finder.countries.uzbekistan', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.UZBEKISTAN },
  { value: 'Vietnam', text: 'product-finder.countries.vietnam', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.VIETNAM },
  { value: 'Yemen', text: 'product-finder.countries.yemen', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.YEMEN },
  { value: 'Australia', text: 'product-finder.countries.australia', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.AUSTRALIA },
  { value: 'Fiji', text: 'product-finder.countries.fiji', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.FIJI },
  { value: 'Kiribati', text: 'product-finder.countries.kiribati', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.KIRIBATI },
  { value: 'Marshall Islands', text: 'product-finder.countries.marshall-islands', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.MARSHALL_ISLANDS },
  { value: 'Micronesia', text: 'product-finder.countries.micronesia', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.MICRONESIA },
  { value: 'Nauru', text: 'product-finder.countries.nauru', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.NAURU },
  { value: 'New Zealand', text: 'product-finder.countries.new-zealand', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.NEW_ZEALAND },
  { value: 'Palau', text: 'product-finder.countries.palau', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.PALAU },
  { value: 'Papua New Guinea', text: 'product-finder.countries.papua-new-guinea', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.PAPUA_NEW_GUINEA },
  { value: 'Samoa', text: 'product-finder.countries.samoa', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.SAMOA },
  { value: 'Solomon Islands', text: 'product-finder.countries.solomon-islands', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.SOLOMON_ISLANDS },
  { value: 'Tonga', text: 'product-finder.countries.tonga', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.TONGA },
  { value: 'Tuvalu', text: 'product-finder.countries.tuvalu', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.TUVALU },
  { value: 'Vanuatu', text: 'product-finder.countries.vanuatu', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.VANUATU },
  { value: 'Algeria', text: 'product-finder.countries.algeria', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.ALGERIA },
  { value: 'Angola', text: 'product-finder.countries.angola', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.ANGOLA },
  { value: 'Benin', text: 'product-finder.countries.benin', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.BENIN },
  { value: 'Botswana', text: 'product-finder.countries.botswana', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.BOTSWANA },
  { value: 'Burkina Faso', text: 'product-finder.countries.burkina-faso', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.BURKINA_FASO },
  { value: 'Burundi', text: 'product-finder.countries.burundi', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.BURUNDI },
  { value: 'Cameroon', text: 'product-finder.countries.cameroon', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.CAMEROON },
  { value: 'Central African Republic', text: 'product-finder.countries.central-african-republic', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.CENTRAL_AFRICAN_REPUBLIC },
  { value: 'Chad', text: 'product-finder.countries.chad', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.CHAD },
  { value: 'Comoros', text: 'product-finder.countries.comoros', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.COMOROS },
  { value: 'Djibouti', text: 'product-finder.countries.djibouti', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.DJIBOUTI },
  { value: 'Egypt', text: 'product-finder.countries.egypt', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.EGYPT },
  { value: 'Equatorial Guinea', text: 'product-finder.countries.equatorial-guinea', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.EQUATORIAL_GUINEA },
  { value: 'Eritrea', text: 'product-finder.countries.eritrea', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.ERITREA },
  { value: 'Eswatini', text: 'product-finder.countries.eswatini', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.ESWATINI },
  { value: 'Ethiopia', text: 'product-finder.countries.ethiopia', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.ETHIOPIA },
  { value: 'Gabon', text: 'product-finder.countries.gabon', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.GABON },
  { value: 'Gambia', text: 'product-finder.countries.gambia', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.GAMBIA },
  { value: 'Ghana', text: 'product-finder.countries.ghana', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.GHANA },
  { value: 'Guinea', text: 'product-finder.countries.guinea', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.GUINEA },
  { value: 'Guinea-Bissau', text: 'product-finder.countries.guinea-bissau', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.GUINEA_BISSAU },
  { value: 'Ivory Coast', text: 'product-finder.countries.ivory-coast', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.IVORY_COAST },
  { value: 'Kenya', text: 'product-finder.countries.kenya', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.KENYA },
  { value: 'Lesotho', text: 'product-finder.countries.lesotho', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.LESOTHO },
  { value: 'Liberia', text: 'product-finder.countries.liberia', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.LIBERIA },
  { value: 'Madagascar', text: 'product-finder.countries.madagascar', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.MADAGASCAR },
  { value: 'Malawi', text: 'product-finder.countries.malawi', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.MALAWI },
  { value: 'Mali', text: 'product-finder.countries.mali', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.MALI },
  { value: 'Mauritania', text: 'product-finder.countries.mauritania', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.MAURITANIA },
  { value: 'Mauritius', text: 'product-finder.countries.mauritius', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.MAURITIUS },
  { value: 'Morocco', text: 'product-finder.countries.morocco', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.MOROCCO },
  { value: 'Mozambique', text: 'product-finder.countries.mozambique', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.MOZAMBIQUE },
  { value: 'Namibia', text: 'product-finder.countries.namibia', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.NAMIBIA },
  { value: 'Niger', text: 'product-finder.countries.niger', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.NIGER },
  { value: 'Nigeria', text: 'product-finder.countries.nigeria', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.NIGERIA },
  { value: 'Rwanda', text: 'product-finder.countries.rwanda', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.RWANDA },
  { value: 'Seychelles', text: 'product-finder.countries.seychelles', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.SEYCHELLES },
  { value: 'Sierra Leone', text: 'product-finder.countries.sierra-leone', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.SIERRA_LEONE },
  { value: 'Somalia', text: 'product-finder.countries.somalia', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.SOMALIA },
  { value: 'South Africa', text: 'product-finder.countries.south-africa', currencySymbol: CurrencySymbol.USDOLLAR, countryCode: CountryCodes.SOUTH_AFRICA }
]

export default {
  computed: {
    availableCountryItems () {
      return Countries
        .filter(({ countryCode }) => !company.config.availableCountryCodes || company.config.availableCountryCodes.includes(countryCode.toLocaleLowerCase()))
        .map(c => ({
          value: c.countryCode,
          text: this.$t(c.text)
        }))
    },
    availableCountries () {
      return Countries
        .filter(({ countryCode }) => !company.config.availableCountryCodes || company.config.availableCountryCodes.includes(countryCode.toLocaleLowerCase()))
        .map(country => ({
          value: country.value,
          text: this.$t(country.text),
          key: country.text,
          countryCode: country.countryCode,
          currencySymbol: country.currencySymbol
        }))
    }
  }
}
